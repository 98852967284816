@import "base/common-dependencies";
@import "../common/mixins";

.authLoginUserIcon {
  display: none;
  font-size: $gw-base-font-size + 1.5;

  @include media('<=phoneLandscape') {
    display: inline-block;
  }
}

.authLoginUserName {
  display: inline-block;

  @include media('<=phoneLandscape') {
    display: none;
  }
}
