@import "base/common-dependencies";

$gw-radio-image-tile-height: 235px !default;
$gw-radio-image-tile-border: 1px solid $border-color !default;
$gw-radio-image-tile-border-radius: 2px !default;
$gw-radio-image-tile-label-height: 30px !default;
$gw-radio-image-tile-label-width: 197px !default;
$gw-radio-image-tile-label-font-size: 20px !default;
$gw-radio-image-tile-label-margin-bottom: 13px !default;
$gw-radio-image-tile-label-text-shadow: 0 1px 0 0 $white-color !default;

@mixin gwRadioImageTile() {
  height: $gw-radio-image-tile-height;
  width: auto;
  border: $gw-radio-image-tile-border;
  border-radius: $gw-radio-image-tile-border-radius;
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    background-color: $grey-light-10-color;
    cursor: pointer;
  }

  @include media('<=tabletLandscape') {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.gw_radioImageTile_container {
  @include gwRadioImageTile();

  background-color: $white-color;
}

.gw_radioImageTile_container__selected {
  @include gwRadioImageTile();

  background-color: $grey-light-20-color;
}

.gw_radioImageTile_label {
  height: $gw-radio-image-tile-label-height;
  width: $gw-radio-image-tile-label-width;
  color: $grey-dark-90-color;
  font-family: "Source Sans Pro";
  font-size: $gw-radio-image-tile-label-font-size;
  margin-bottom: $gw-radio-image-tile-label-margin-bottom;
  text-align: center;
  text-shadow: $gw-radio-image-tile-label-text-shadow;

  @include media('<=phoneLandscape') {
    font-size: $x * 2;
    height: $x * 3;
    max-width: $x * 18;
  }
}

.gw_radioImageTile_img {
  height: 125px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: $x * 3;

  @include media('<=phoneLandscape') {
    height: $x * 12.5;
    max-width: $x * 18;
  }
}

.gw_radioImageTile_button {
  display: flex;
  align-items: center;
  justify-content: center;
}
