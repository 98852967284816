@import "base/common-dependencies";

$gw-details-box-padding: 16px !default;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.mapContainer {
  flex: auto;
  position: relative;
}

.map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.repairFacilityDetails {
  padding: $gw-details-box-padding;
  align-content: flex-end;
}

.selectInfoBox {
  position: absolute;
  background-color: rgba($black-color, 0.6);
  white-space: nowrap;
  color: $white-color;
  padding: 5px 10px;
  margin-bottom: 30px;
  border-radius: 15px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
