@import "base/common-dependencies";

$option-search-row: 20px;

.container {
  padding-top: $option-search-row / 1.2;
  color: $grey-dark-80-color;

  .full {
    width: 100%;
  }

  .inline {
    display: inline-flex;
  }

  .option {
    display: inline-flex;
    cursor: pointer;
    margin-left: $option-search-row / 2;
  }

  .policy,
  .incident,
  .navigator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 $option-search-row;
    width: $option-search-row;
    height: $option-search-row;
    border-radius: $option-search-row / 2;
    position: relative;
    color: $grey-light-10-color;
    box-shadow: 0 0 3px 1px rgba($black-color, 0.5);
  }

  .incident {
    background-color: $gw-map-color-red;
    font-size: 85%;
  }

  .policy {
    background-color: $gw-map-color-purple;
  }

  .navigator {
    background-color: $gw-map-color-green;
  }

  .label {
    padding-left: $option-search-row / 4;
    color: $tabs-active-marker;
  }
}
