@import "base/common-dependencies";

$gw-facilities-list-padding: 16px !default;

.content {
  display: flex;
  align-items: center;
  padding: $gw-facilities-list-padding 0;
}

.entry {
  padding-left: $gw-facilities-list-padding;
}

.primaryDetail {
  color: $black-color;
}

.secondaryDetail {
  color: $grey-dark-80-color;
}

.selected {
  .detail {
    @include media('>phoneLandscape') {
      color: $white-color;
    }
  }
}

.icon {
  font-size: $font-xl;
  padding-right: $gw-facilities-list-padding;
  margin-left: auto;
  min-width: 2*$gw-facilities-list-padding;

  .check {
    color: $white-color;
  }

  .angle {
    color: $grey-color;
  }
}
