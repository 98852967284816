@import "base/common-dependencies";

$info-margin: 5px !default;

.info {
  text-align: justify;
  margin-bottom: $info-margin;

  .icon {
    color: $theme-color;
  }
}

.map {
  width: 100%;
  height: 400px;
}
