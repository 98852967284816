@import "base/common-dependencies";

$gw-rating-padding: 6px;
$gw-star-padding: 1px;
$gw-rate-active-unselected-color: #e7711b;
$gw-rate-inactive-selected-color: #5081ac;

.content {
  display: flex;
  flex-flow: row nowrap;
  padding-top: $gw-rating-padding;
  font-weight: bold;

  * {
    height: 100%;
    flex: 0 0 auto;
  }
}

.numericRate {
  color: $gw-rate-active-unselected-color;
}

.undefinedRate {
  color: $grey-light-40-color;
}

.starsRate {
  padding: 0 4*$gw-star-padding;
}

.star {
  font-size: $font-m;
  padding: 0 $gw-star-padding;
}

.activeStar {
  @extend .star;

  color: $gw-rate-active-unselected-color;
}

.inactiveStar {
  @extend .star;

  color: $grey-light-40-color;
}

.selected {
  @include media('>phoneLandscape') {
    .numericRate,
    .undefinedRate,
    .activeStar {
      color: $white-color;
    }

    .inactiveStar {
      color: $gw-rate-inactive-selected-color;
    }
  }
}
