@import "base/common-dependencies";

$marker-radius: 10px;

.marker {
  cursor: pointer;
  position: absolute;
  width: 2*$marker-radius;
  height: 2*$marker-radius;
  border-radius: $marker-radius;
  box-shadow: 0 0 3px 1px rgba($black-color, 0.5);
  text-align: center;
  padding-top: $marker-radius/5;
  transform: translateX(-$marker-radius) translateY(-$marker-radius);
}

.icon {
  color: $white-color;
  font-size: 1.5*$marker-radius;
}
