@import "base/common-dependencies";

$gw-separator: 1px solid $grey-light-30-color;
$gw-icon-padding: 12px;

.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.loader {
  @extend .placeholder;

  margin-top: -$gw-loader-top-margin;
}

.list {
  height: 100%;
  overflow-y: auto;

  li {
    border-bottom: $gw-separator;

    &:first-child {
      @include media('<=phoneLandscape') {
        border-top: $gw-separator;
      }
    }
  }

  @include media('>phoneLandscape') {
    li:last-of-type hr {
      display: none;
    }
  }
}

.selected {
  @include media('>phoneLandscape') {
    background-color: $brand-alt-01;
  }
}

.unselected:hover {
  @include media('>phoneLandscape') {
    background-color: $grey-light-20-color;
  }
}

.message {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: $grey-light-60-color;

  .icon {
    font-size: $font-xxl;
    color: $accent-color;
    padding: $gw-icon-padding;
  }
}
