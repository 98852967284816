@import "base/common-dependencies";

$gw-fnol-icon-size: 7em !default;
$gw-fnol-title-font-size: 4rem !default;

.title {
  color: $grey-dark-90-color;
  font-size: $gw-fnol-title-font-size;
  font-weight: $gw-font-weight-light;
  line-height: $gw-fnol-title-font-size;
  text-align: center;
  margin-bottom: 1.5rem;
}

.claimNumber {
  color: $grey-dark-90-color;
  font-size: $gw-font-sm;
  font-weight: $gw-font-weight-bold;
  text-align: center;
  margin-bottom: 1rem;
}

.phoneInline {
  font-weight: $gw-font-weight-bold;
}

.phoneIcon {
  height: $gw-fnol-icon-size;
  width: $gw-fnol-icon-size;
  line-height: $gw-fnol-icon-size;
  color: $gw-fnol-confirm-icon-color;
  text-align: center;
  background-color: $gw-fnol-confirm-icon-bg-color;
  border-radius: 50%;
  margin: 3em auto;

  :global(.fa-phone) {
    line-height: inherit;
  }
}

.links {
  margin-top: 1rem;
  text-align: center;

  > * {
    margin: 1rem 0;
  }
}

