@import "base/common-dependencies";

$gw-detail-break: 3px 0 !default;

.container {
  display: flex;
  flex-direction: column;
}

.detail {
  padding: $gw-detail-break;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.primaryDetail {
  color: $black-color;
}

.secondaryDetail {
  color: $grey-dark-80-color;
}

.repairButton {
  align-self: flex-end;
}
