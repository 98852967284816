@import "base/common-dependencies";
@import "../common/mixins";

#talkdesk-button {
  display: block;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: var(--trigger-button-width, 64px);
  height: var(--trigger-button-height, 64px);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-image: var(--chat-icon, url(https://prd-cdn-talkdesk.talkdesk.com/cdn-assets/latest/talkdesk/product/app-icons/sms.svg));
  background-color: var(--chat-launcher-color, #1f1f1f);
  background-color: #00539f !important;
  background-size: 56%;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color .1s ease-in-out;
  position: fixed;
  right: 20px;
  z-index: 999;
  bottom: 20px;
}

.heading {
  @include heading;

  margin-bottom: 8px;
}

.actionsWrapper {
  @include actions-wrapper;
}

.actionsWrapperSingleButton {
  justify-content: flex-end;
}

.button {
  @include action-button;
}

.inputGroup {
  @include input-group;
}

.error {
  @include error-message;

  margin-bottom: $x*2;
}

.bottomText {
  @include bottom-text;
}

.divider {
  margin: $x*2 0;
}

.socialLogin {
  display: flex;
  justify-content: left;
  margin: $x*3 0;

  > li {
    border: 1px solid;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    font-size: $x*3;
    margin-right: $x;
    cursor: pointer;

    &.twitter {
      border-color: $gw-twitter-logo-color;
      color: $gw-twitter-logo-color;
    }

    &.fb {
      border-color: $gw-facebook-sharing-background-color;
      color: $gw-facebook-sharing-background-color;
    }

    &.google {
      border-color: $gw-google-logo-color;
      color: $gw-google-logo-color;
    }
  }
}

.andCookieBanner {
    position: fixed;
    z-index: 1000;
    background: black;
    color: white;
    padding: 20px;
    width: 500px;
    text-align: center;
    margin-left: 200px;
}
