$gw-line-chart-height: 39px;
$gw-line-chart-text-color: #373737;
$gw-line-chart-font-size: 13px;
$gw-line-chart-left-height: 15px;
$gw-line-chart-svg-height: 10px;
$gw-line-chart-rect-height: 4px;

.gw-line-chart-container {
  .text {
    visibility: hidden;
    color: $gw-line-chart-text-color;
    font-size: $gw-line-chart-font-size;
    word-wrap: break-word;
  }

  .left {
    height: $gw-line-chart-left-height;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .chart {
    height: $gw-line-chart-svg-height;
  }

  .rect {
    height: $gw-line-chart-rect-height;
  }
}

.gw-line-chart-container:hover .text {
  visibility: visible;
}
