@import "base/common-dependencies";

.container {
  font-size: 11px;
  color: $gw-tab-pill-bg-color;
  background-color: $white-color;
  border-radius: $container-radius;
  font-weight: $gw-font-weight-regular;
  // to override google native style css
  margin: 10px 10px 25px 7px !important;
  left: 0 !important;
  bottom: 0 !important;

  .mapStyle {
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    float: left;
    user-select: none;
    border-left: 1px;
    box-shadow: 0 1px 4px -1px rgba($black-color, 0.3);
    padding: $x;
    border-radius: $container-radius;
  }

  .mapStyle:hover {
    background-color: $modal-bottom-bar-color;
    color: $black-color;
    font-weight: $gw-font-weight-bold;
  }

  .active {
    color: $black-color;
    font-weight: $gw-font-weight-bold;
  }
}
