@import "base/common-dependencies";

$bottom-border-size: 1px;
$top-margin: 2em;
$bottom-padding: 0.5em;
$icon-size: 1em;
$input-field-padding: 1.5em;
$label-opacity: 0.65;

.srField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  padding-bottom: $input-field-padding;

  label {
    font-size: $fontSizeLabel;
    font-weight: normal;
    opacity: $label-opacity;
  }
}

.srList {
  &:not(:first-child) {
    margin-top: $top-margin;
  }

  .serviceRequest {
    border-bottom: $bottom-border-size solid $grey-light-color;
    padding-bottom: $bottom-padding;
  }
}

.status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .label {
    font-size: $font-xl;
  }
}

.statusIcon {
  color: $active-color;
  font-size: $icon-size;
  margin-right: $icon-size/4;
  margin-top: 0;

  &.offTrack {
    color: $alert-color;
  }
}
