@import "base/common-dependencies";

$option-search-row: 20px;
$option-search-icon: 1.3em;
$min-width-search-row: 235px;

.container {
  position: absolute;
  top: $gw-root-font-size;
  left: $gw-root-font-size;
  box-shadow: 2px 3px 4px -1px rgba($black-color, 0.4);
  background-color: $white-color;

  @include media('<=phoneLandscape') {
    width: calc(100% - 2 * #{$gw-root-font-size});
  }

  @include media('>phoneLandscape') {
    min-width: $min-width-search-row;
    width: 65%;
  }

  .toggle {
    position: relative;
    cursor: pointer;

    .icon {
      position: absolute;
      right: $gw-root-font-size / 2;
      font-size: $option-search-icon;
      color: $grey-light-30-color;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  input {
    padding-right: calc(#{$option-search-icon} + #{$gw-root-font-size});
    // override default
    cursor: pointer;
    border-radius: 0;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid $dropdown-border-color;
  }

  .dropdown {
    width: 100%;

    .placeList {
      display: inline-block;
      width: 100%;
    }

    .place {
      cursor: pointer;
      display: inline-block;
      padding: $gw-root-font-size;
      width: 100%;
      height: auto;
      min-height: $option-search-row * 2;
      border-bottom: 1px solid $dropdown-border-color;
    }

    .option {
      display: inline-flex;
      width: 100%;

      .policy,
      .incident,
      .navigator {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 $option-search-row;
        width: $option-search-row;
        height: $option-search-row;
        border-radius: $option-search-row / 2;
        position: relative;
        color: $grey-light-10-color;
        box-shadow: 0 0 3px 1px rgba($black-color, 0.5);
      }

      .incident {
        background-color: $gw-map-color-red;
        font-size: 85%;
      }

      .policy {
        background-color: $gw-map-color-purple;
      }

      .navigator {
        background-color: $gw-map-color-green;
      }

      .label {
        padding-left: $option-search-row;
      }
    }
  }
}
