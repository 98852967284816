@import "base/common-dependencies";
@import "../common/mixins";

.heading {
  @include heading;
}

.actionsWrapper {
  @include actions-wrapper($min-margin-bottom: true);

  justify-content: flex-end;
}

.button {
  @include action-button;
}

.inputGroup {
  @include input-group;
}

.error {
  @include error-message;

  margin-bottom: $x*2;
}

.bottomText {
  @include bottom-text;
}

.divider {
  margin: $x*2 0;
}
