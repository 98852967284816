@import "base/common-dependencies";
@import "../common/mixins";

.andAuthContent {
  padding: 12*$x $x*3 $x*3 $x*3;
  display: flex;
  justify-content: center;

  // any component view inside gwAuthContent should have a max-width
  > [gw-auth-component] {
    flex-grow: 1;
    max-width: 60 * $x;
  }
}

.heading {
  @include heading;

  margin-bottom: 8px;
}
