@import "base/common-dependencies";
@import "../common/mixins";

.heading {
  @include heading;

  margin-bottom: $x;
}

.actionsWrapper {
  @include actions-wrapper;
}

.button {
  @include action-button;
}

.error {
  @include error-message;

  margin-bottom: $x*2;
}
