@import "base/common-dependencies";

$gw-arrow-font-size: $font-xxl;
$gw-square-button-size: 30px;
$gw-pagination-label-margin-right: 8px;
$gw-pagination-label-margin-left: 20px;
$gw-previous-nchild-margin-right: 8px;

.label {
  flex: 0 1 auto;
  align-self: center;
  margin-right: $gw-pagination-label-margin-right;
  color: $table-th-text-color;
}

.rate {
  @include media('>tabletLandscape') {
    margin-left: $gw-pagination-label-margin-left;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}

.button {
  outline: 0;
  padding: 0;
  min-width: $gw-square-button-size;
  max-width: $gw-square-button-size;
  min-height: $gw-square-button-size;
  max-height: $gw-square-button-size;

  i {
    font-size: $gw-arrow-font-size;
  }
}

.button:disabled {
  padding: 0;
}

.button:disabled:hover {
  padding: 0;
}

.previous {
  flex: 0 0 auto;
  align-self: auto;
  margin-right: $gw-previous-nchild-margin-right;
}

.next:nth-child(3) {
  flex: 0 0 auto;
  align-self: auto;

  i {
    font-size: $gw-arrow-font-size;
  }
}
