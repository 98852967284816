@import "base/common-dependencies";

$and-tile-height: 40px;

.andMobileButton {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.andMobileFaqQuery {
  width: 100%;
  margin-top: 3px;
}

.andMobileFAQ {
  margin-top: 10px;
  margin-bottom: 10px;
}

.andTileContainer {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.andTile {
  height: $and-tile-height;
  padding: 0 3px;
  display: flex;

  @include media('<=phonePortrait') {
    width: 30px;
    height: $and-tile-height;
  }
}

.first {
  display: none; // ANDIE Hiding this due to Style guide work (CBPDIG-1250) keeping below properties in case its reverted
  height: 100%;
  font-size: x-large;
  font-weight: bold;
  line-height: $and-tile-height;
  text-align: center;

  @include media('<=phonePortrait') {
    width: 100%;
    font-size: xx-large;
  }
}

.second {
  margin-left: -45px;
  margin-top: 30px;
  word-wrap: break-word;
  display: flex;
  font-size: smaller;
  align-items: center;
  text-align: left;
  color: #000;
}

.andPullRight {
  float: right;
  margin-right: 10px;
}
