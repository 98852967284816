@import "base/common-dependencies";

$and-search-button-container-padding-bottom: 1.5em !default;
$and-error-message-font-size: 1.4rem !default;

.andSearchButtonContainer {
  display: flex;
  padding-bottom: $and-search-button-container-padding-bottom;
  margin-top: 10px;

  .andSearchButton {
    margin-left: -1px;
  }
}

.andErrorMessage {
  font-size: $and-error-message-font-size;
  display: inline-block;
  color: $error-text-color;
}
