@import "base/common-dependencies";

$vendor-information-margin-top: 7px;
$vendor-information-max-width: 230px;

.container {
  height: 100%;
  width: 100%;
}

.vendorName {
  font-weight: bold;
}

.vendorInformation {
  color: $grey-dark-80-color;
  margin-top: $vendor-information-margin-top;
  max-width: $vendor-information-max-width;
}
