@import "base/common-dependencies";
@import "../common/mixins";

.heading {
  @include heading;
}

.button {
  @include action-button;
}

.actionsWrapper {
  @include actions-wrapper($min-margin-bottom: true);
}

.inputGroup {
  @include input-group;
}

.error {
  @include error-message;
}

.infoMessage {
  margin-bottom: $x;
  line-height: $gw-base-font-size + 0.6;
}
