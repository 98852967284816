@import "base/common-dependencies";

$gw-selector-height: 560px !default;

.container {
  display: flex;
  align-items: stretch;

  @include media('<=phoneLandscape') {
    flex-wrap: wrap;
  }

  @include media('>phoneLandscape') {
    height: $gw-selector-height;
    border: 1px solid $grey-light-30-color;
  }
}

.repairFacilitiesMap {
  position: relative;
  order: 1;
  width: 100%;

  @include media('>phoneLandscape') {
    flex: 2;
    height: 100%;
  }
}

.repairFacilitiesList {
  order: 2;
  width: 100%;

  @include media('>phoneLandscape') {
    flex: 1;
    height: 100%;
  }
}

.searchButton {
  margin-bottom: 15px;
  height: 70px;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: $white-color;
  font-size: $font-xl;
  background-image: linear-gradient(rgba($black-color, 0.6), rgba($black-color, 0.6)), url('../images/common/search_map_button.png');
}
