@import "base/common-dependencies";
@import "~gw-portals-auth-angular/components/common/mixins";

.heading {
  @include heading;

  margin-bottom: $x;
}

.actionsWrapper {
  @include actions-wrapper;
}

.button {
  @include action-button;
}

.inputGroup {
  @include input-group;
}

.error {
  @include error-message;

  margin-bottom: $x*2;
}

.infoMessage {
  margin-bottom: $x;
  line-height: $gw-base-font-size + 0.6;
}
