@import "base/common-dependencies";
@import "~gw-portals-auth-angular/components/common/mixins";

.enrollmentDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.enrollmentForm {
  max-width: 60 * $x;
}

.heading {
  @include heading;

  margin-bottom: $x;
}

.actionsWrapper {
  @include actions-wrapper;
}

.button {
  margin-left: $x;

  @include action-button;
}

.inputGroup {
  @include input-group;
}

.error {
  @include error-message;

  margin-bottom: $x*2;
}

.infoMessage {
  margin-bottom: $x;
  line-height: $gw-base-font-size + 0.6;
}

// Copied from fnol success page (with these hard-coded values).
.enrollmentSuccess {
  border: solid;
  border-width: 1px;
  padding: 14px;
  background-color: $saved-success-color;
  border-color: $saved-success-border-color;
  color: $success-response-color;
  border-radius: 2px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba($white-color, 0.5);

  .congratulations {
    color: $success-response-color;
    margin: 0;
    font-size: $gw-font-md;
  }
}
